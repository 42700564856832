export function cookieConsentEvent(consent) {
  return {
    event: 'cookie_consent_changed',
    metadata: cookieConsent(consent)
  }
}

export function cookieConsent({ permissions }) {
  return {
    cookieconsent: permissions,
  }
}
