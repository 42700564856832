import { asRouteMap } from '@kaliber/routing'
import groq from 'groq'
import { parameterExtraction, docWithSeo } from '@kaliber/sanity-routing/sanity'
import { cookieConsent } from '/machinery/tracking/cookieConsent'
import dayjs from 'dayjs'

const { extract, language, slug } = parameterExtraction

const snippets = createGroqSnippets()
const routeData = getRouteData()

export const routeMap = asRouteMap(
  {
    root: '',
    api: {
      path: 'api',

      v1: {
        path: 'v1',

        url: {
          path: 'document/path',
          data: requestHandlers => requestHandlers.determineDocumentPath,
        },

        cookieConsent: {
          path: 'cookieConsent',
          data: requestHandlers => requestHandlers.setCookieConsent,
        }
      },

      sitemap: {
        path: 'sitemap',
        data: requestHandlers => requestHandlers.sitemap,
      },

      notFound: '*'
    },
    preview: 'preview',
    app: {
      path: ':language',
      data: routeData.app.data,

      home: {
        path: '',
        data: routeData.app.home.data,
      },
      discipline: {
        path: 'disciplines/:slug',
        data: routeData.app.discipline.data,
      },
      initiative: {
        path: 'our-work/:slug',
        data: routeData.app.initiative.data,
      },
      about: {
        path: 'about',
        data: routeData.app.about.data,
      },
      traineeships: {
        path: 'traineeships',
        data: routeData.app.traineeships.data,
      },
      cookies: {
        path: 'cookies',
        data: routeData.app.cookies.data,
      },
      campagne: {
        path: 'campagne/:slug',
        data: routeData.app.campagne.data,
      },
      genericPage: {
        path: ':slug',
        data: routeData.app.genericPage.data,
      },
      events: {
        path: 'events',
        data: routeData.app.events.data,
      },
      notFound: {
        path: '*',
        data: routeData.app.notFound.data
      },
    },
    admin: 'admin',
  },
  { trailingSlash: true }
)

function getRouteData() {
  return {
    app: {
      data: {
        groq: ({ params: { language } }) => ({
          settings: groq`*[_id == 'settings'][0]`,
          cookieBarSettings: [
            groq`*[_id == 'cookieBarSettings' && language == $language] | order(_updatedAt desc) [0] {
              content[] {
                _type == 'block' => { ..., markDefs[] },
                markDefs[] {
                  ...,
                  _type == 'internalLink' => { ref->${snippets.linkableDocumentObject} }
                },
              },
          }`,
            { language }
          ],
          disciplines: [
            groq`*[_type == 'discipline' && language == $language] | order(publicationDate desc) {
              _id,
              _type,
              slug,
              language,
              title
            }`,
            { language }
          ],
          initiatives: [
            groq`*[_type == 'initiative' && language == $language] | order(_updatedAt desc) {
              _id,
              _type,
              slug,
              language,
              title
            }`,
            { language }
          ],
          cookies: [
            groq`*[_type == 'cookies' && language == $language] | order(_updatedAt desc) [0] {
              _id,
              _type,
              language,
              title
            }`,
            { language }
          ],
          notFound: [
            groq`*[_type == 'notFound' && language == $language] | order(_updatedAt desc) [0] {
              title,
              text,
              buttonText,
              image,
            }`,
            { language }
          ],
        }),
        fetch: {
          cookieConsent: requestHandlers => requestHandlers.getCookieConsent,
        },
        derived: ({ data, derived, params }) => ({
          ...derived,
          dataLayer: {
            ...derived.dataLayer,
            language: params.language,
            metadata: {
              ...derived.dataLayer?.metadata,
              ...cookieConsent(data?.cookieConsent?.consent ?? null)
            }
          }
        }),
      },

      home: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: [
              groq`*[_type == 'home' && language == $language] | order(_updatedAt desc) [0] {
                ...,
                title,
                hero ${snippets.heroObject},
                content[] {
                  _type,
                  _key,
                  _type == 'block' => { ..., markDefs[] },
                  _type == 'softCtaInternal' => ${snippets.internalLinkObject},
                  _type == 'softCtaExternal' => ${snippets.externalLinkObject},
                  _type == 'ctaButtonInternal' => ${snippets.internalLinkObject},
                  _type == 'ctaButtonExternal' => ${snippets.externalLinkObject},
                  _type == 'videoText' => ${snippets.videoTextObject},
                  _type == 'imageGrid' => { images[] },
                  _type == 'disciplineAreaList' => ${snippets.disciplineAreaListObject},
                  _type == 'events' => ${snippets.eventsLatestThreeObject},
                  _type == 'campaignBanner' => ${snippets.campaignBannerObject},
                  markDefs[] {
                    ...,
                    _type == 'internalLink' => { ref->${snippets.linkableDocumentObject} }
                  }
                },
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
                seo,
              }`,
              { language }
            ],
          }),
          fetch: {
            jobs: requestHandlers => () => requestHandlers.getJobsWithLimit(6)
          },
          derived: ({ data, derived }) => data.doc ? {
            doc: docWithSeo(data.doc, {
              title: data.doc.title,
              description: data.doc.hero?.text,
              social: {
                shareImage: data.settings?.shareImage
              }
            }),
            dataLayer: {
              ...derived.dataLayer,
              title: data.doc.title,
              metadata: {
                ...derived.dataLayer?.metadata,
                content: {
                  type: data.doc._type,
                  title: data.doc.title,
                  id: data.doc._id,
                  createdat: dayjs(data.doc._createdAt).format('YYYY-MM-DD'),
                  updatedat: dayjs(data.doc._updatedAt).format('YYYY-MM-DD'),
                  language: data.doc.language,
                }
              }
            }
          } : notFound(data),
          extractParams: { home: extract(language) }
        }
      },

      discipline: {
        data: {
          groq: ({ params: { slug, language } }) => ({
            doc: [
              groq`*[_type == 'discipline' && language == $language && slug.current == $slug] | order(_updatedAt desc) [0] {
                ...,
                title,
                hero ${snippets.heroObject},
                content[] {
                  _type,
                  _key,
                  _type == 'block' => { ..., markDefs[] },
                  _type == 'softCtaInternal' => ${snippets.internalLinkObject},
                  _type == 'softCtaExternal' => ${snippets.externalLinkObject},
                  _type == 'ctaButtonInternal' => ${snippets.internalLinkObject},
                  _type == 'ctaButtonExternal' => ${snippets.externalLinkObject},
                  _type == 'imageGrid' => { images[] },
                  _type == 'facts' => ${snippets.factsObject},
                  _type == 'anchorToJobCards' => ${snippets.anchorToJobCardsObject},
                  _type == 'initiativeList' => ${snippets.initiativeListObject},
                  _type == 'events' => ${snippets.eventsLatestThreeObject},
                  markDefs[] {
                    ...,
                    _type == 'internalLink' => { ref->${snippets.linkableDocumentObject} }
                  }
                },
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
                seo,
              }`,
              { language, slug }
            ]
          }),
          fetch: {
            jobs: requestHandlers => requestHandlers.getJobsByDiscipline
          },
          extractParams: { discipline: extract(language, slug) },
          derived: ({ data, derived }) => data.doc ? {
            doc: docWithSeo(data.doc, {
              title: data.doc.title,
              social: {
                shareImage: data.doc.hero?.image ?? data.settings?.shareImage
              }
            }),
            dataLayer: {
              ...derived.dataLayer,
              title: data.doc.title,
              metadata: {
                ...derived.dataLayer?.metadata,
                content: {
                  type: data.doc._type,
                  title: data.doc?.hero?.title,
                  discipline: data.doc.title,
                  id: data.doc._id,
                  createdat: dayjs(data.doc._createdAt).format('YYYY-MM-DD'),
                  updatedat: dayjs(data.doc._updatedAt).format('YYYY-MM-DD'),
                  language: data.doc.language,
                }
              }
            }
          } : notFound(data),
        },
      },

      initiative: {
        data: {
          groq: ({ params: { slug, language } }) => ({
            doc: [
              groq`*[_type == 'initiative' && language == $language && slug.current == $slug] | order(_updatedAt desc) [0] {
                ...,
                title,
                discipline->{ ..., title },
                hero ${snippets.heroVideoObject},
                content[] {
                  _type,
                  _key,
                  _type == 'block' => { ..., markDefs[] },
                  _type == 'softCtaInternal' => ${snippets.internalLinkObject},
                  _type == 'softCtaExternal' => ${snippets.externalLinkObject},
                  _type == 'ctaButtonInternal' => ${snippets.internalLinkObject},
                  _type == 'ctaButtonExternal' => ${snippets.externalLinkObject},
                  _type == 'imageGrid' => { images[] },
                  _type == 'facts' => ${snippets.factsObject},
                  _type == 'employeeQuote' => ${snippets.employeeQuoteObject},
                  _type == 'dataVisualization' => ${snippets.dataVisualizationObject},
                  _type == 'visualWithQuoteFact' => ${snippets.visualWithQuoteFactObject},
                  _type == 'anchorToJobCards' => ${snippets.anchorToJobCardsObject},
                  _type == 'videoText' => ${snippets.videoTextObject},
                  _type == 'ctaNextInitiative' => ${snippets.ctaNextInitiativeObject},
                  _type == 'events' => ${snippets.eventsLatestThreeObject},
                  _type == 'campaignBanner' => ${snippets.campaignBannerObject},
                  markDefs[] {
                    ...,
                    _type == 'internalLink' => { ref->${snippets.linkableDocumentObject} }
                  }
                },
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
                seo,
              }`,
              { language, slug }
            ]
          }),
          fetch: {
            jobs: requestHandlers => () => requestHandlers.getJobsWithLimit(6)
          },
          extractParams: { initiative: extract(language, slug) },
          derived: ({ data, derived }) => {
            return data.doc ? {
              doc: docWithSeo(data.doc, {
                title: data.doc.title,
                social: {
                  shareImage: data.doc.hero?.image ?? data.settings?.shareImage
                }
              }),
              dataLayer: {
                ...derived.dataLayer,
                title: data.doc.title,
                metadata: {
                  ...derived.dataLayer?.metadata,
                  content: {
                    type: data.doc._type,
                    title: data.doc?.hero?.title,
                    id: data.doc._id,
                    createdat: dayjs(data.doc._createdAt).format('YYYY-MM-DD'),
                    updatedat: dayjs(data.doc._updatedAt).format('YYYY-MM-DD'),
                    language: data.doc.language,
                    initiative: data.doc.title,
                  }
                }
              }
            } : notFound(data)
          },
        },
      },

      about: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: [
              groq`*[_type == 'about' && language == $language] | order(_updatedAt desc) [0] {
                ...,
                hero ${snippets.heroObject},
                content[] {
                  _type,
                  _key,
                  _type == 'block' => { ..., markDefs[] },
                  _type == 'softCtaInternal' => ${snippets.internalLinkObject},
                  _type == 'softCtaExternal' => ${snippets.externalLinkObject},
                  _type == 'ctaButtonInternal' => ${snippets.internalLinkObject},
                  _type == 'ctaButtonExternal' => ${snippets.externalLinkObject},
                  _type == 'videoText' => ${snippets.videoTextObject},
                  _type == 'imageGrid' => { images[] },
                  _type == 'visualWithQuoteFact' => ${snippets.visualWithQuoteFactObject},
                  _type == 'ctaBenefits' => {
                    title,
                    buttonText,
                    imageGrid,
                    benefits[] {
                      _key,
                      icon,
                      title,
                      text,
                      image
                    }
                  },
                  _type == 'disciplineAreaList' => ${snippets.disciplineAreaListObject},
                  markDefs[] {
                    ...,
                    _type == 'internalLink' => { ref->${snippets.linkableDocumentObject} }
                  }
                },
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
                seo,
              }`,
              { language }
            ]
          }),
          fetch: {
            jobs: requestHandlers => () => requestHandlers.getJobsWithLimit(6)
          },
          derived: ({ data, derived }) => data.doc ? {
            doc: docWithSeo(data.doc, {
              title: data.doc.title,
              social: {
                shareImage: data.doc.hero?.image ?? data.settings?.shareImage
              }
            }),
            dataLayer: {
              ...derived.dataLayer,
              title: data.doc.title,
              metadata: {
                ...derived.dataLayer?.metadata,
                content: {
                  type: data.doc._type,
                  title: data.doc.title,
                  id: data.doc._id,
                  createdat: dayjs(data.doc._createdAt).format('YYYY-MM-DD'),
                  updatedat: dayjs(data.doc._updatedAt).format('YYYY-MM-DD'),
                  language: data.doc.language,
                }
              }
            }
          } : notFound(data),
          extractParams: { about: extract(language) }
        }
      },

      traineeships: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: [
              groq`*[_type == 'traineeships' && language == $language] | order(_updatedAt desc) [0] {
                ...,
                hero ${snippets.heroObject},
                content[] {
                  _type,
                  _key,
                  _type == 'block' => { ..., markDefs[] },
                  _type == 'videoText' => ${snippets.videoTextObject},
                  _type == 'imageGrid' => { images[] },
                  markDefs[] {
                    ...,
                    _type == 'internalLink' => { ref->${snippets.linkableDocumentObject} }
                  }
                },
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
                'traineeships': ${snippets.traineeshipsQuery},
                seo,
              }`,
              { language }
            ]
          }),
          derived: ({ data, derived }) => data.doc ? {
            doc: docWithSeo(data.doc, {
              title: data.doc.title,
              social: {
                shareImage: data.doc.hero?.image ?? data.settings?.shareImage
              }
            }),
            dataLayer: {
              ...derived.dataLayer,
              title: data.doc.title,
              metadata: {
                ...derived.dataLayer?.metadata,
                content: {
                  type: data.doc._type,
                  title: data.doc.title,
                  id: data.doc._id,
                  createdat: dayjs(data.doc._createdAt).format('YYYY-MM-DD'),
                  updatedat: dayjs(data.doc._updatedAt).format('YYYY-MM-DD'),
                  language: data.doc.language,
                }
              }
            }
          } : notFound(data),
          extractParams: { traineeships: extract(language) }
        }
      },

      cookies: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: [
              groq`*[_type == 'cookies' && language == $language] | order(_updatedAt desc) [0] {
                ...,
                hero ${snippets.heroObject},
                content[] {
                  ...,
                },
                seo,
              }`,
              { language }
            ],
          }),
          derived: ({ data, derived }) => data.doc ? {
            doc: docWithSeo(data.doc, {
              title: data.doc.title,
              social: {
                shareImage: data.settings?.shareImage
              }
            }),
            dataLayer: {
              ...derived.dataLayer,
              title: data.doc.title,
            }
          } : notFound(data),
          extractParams: { cookies: extract(language) }
        }
      },

      campagne: {
        data: {
          groq: ({ params: { slug, language } }) => ({
            doc: [
              groq`*[_type == 'campagne' && language == $language && slug.current == $slug] | order(_updatedAt desc) [0] {
                ...,
                hero ${snippets.heroObject},
                content[] {
                  _type,
                  _key,
                  _type == 'block' => { ..., markDefs[] },
                  _type == 'softCtaInternal' => ${snippets.internalLinkObject},
                  _type == 'softCtaExternal' => ${snippets.externalLinkObject},
                  _type == 'ctaButtonInternal' => ${snippets.internalLinkObject},
                  _type == 'ctaButtonExternal' => ${snippets.externalLinkObject},
                  _type == 'imageGrid' => { images[] },
                  _type == 'facts' => ${snippets.factsObject},
                  _type == 'employeeQuote' => ${snippets.employeeQuoteObject},
                  _type == 'dataVisualization' => ${snippets.dataVisualizationObject},
                  _type == 'visualWithQuoteFact' => ${snippets.visualWithQuoteFactObject},
                  _type == 'anchorToJobCards' => ${snippets.anchorToJobCardsObject},
                  _type == 'videoText' => ${snippets.videoTextObject},
                  _type == 'ctaNextInitiative' => ${snippets.ctaNextInitiativeObject},
                  _type == 'initiativeList' => ${snippets.initiativeListObject},
                  _type == 'events' => ${snippets.eventsLatestThreeObject},
                  markDefs[] {
                    ...,
                    _type == 'internalLink' => { ref->${snippets.linkableDocumentObject} }
                  }
                },
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
                seo,
              }`,
              { language, slug }
            ]
          }),
          fetch: {
            jobs: requestHandlers => () => requestHandlers.getJobsWithLimit(6)
          },
          extractParams: { campagne: extract(language, slug) },
          derived: ({ data, derived }) => data.doc ? {
            doc: docWithSeo(data.doc, {
              title: data.doc.title,
              social: {
                shareImage: data.doc.hero?.image ?? data.settings?.shareImage
              }
            }),
            dataLayer: {
              ...derived.dataLayer,
              title: data.doc.title,
              metadata: {
                ...derived.dataLayer?.metadata,
                content: {
                  type: data.doc._type,
                  title: data.doc.title,
                  id: data.doc._id,
                  createdat: dayjs(data.doc._createdAt).format('YYYY-MM-DD'),
                  updatedat: dayjs(data.doc._updatedAt).format('YYYY-MM-DD'),
                  language: data.doc.language,
                }
              }
            }
          } : notFound(data),
        },
      },

      genericPage: {
        data: {
          groq: ({ params: { slug, language } }) => ({
            doc: [
              groq`*[_type == 'genericPage' && language == $language && slug.current == $slug] | order(_updatedAt desc) [0] {
                ...,
                hero ${snippets.heroObject},
                content[] {
                  _type,
                  _key,
                  _type == 'block' => { ..., markDefs[] },
                  _type == 'softCtaInternal' => ${snippets.internalLinkObject},
                  _type == 'softCtaExternal' => ${snippets.externalLinkObject},
                  _type == 'ctaButtonInternal' => ${snippets.internalLinkObject},
                  _type == 'ctaButtonExternal' => ${snippets.externalLinkObject},
                  _type == 'imageGrid' => { images[] },
                  _type == 'facts' => ${snippets.factsObject},
                  _type == 'employeeQuote' => ${snippets.employeeQuoteObject},
                  _type == 'visualWithQuoteFact' => ${snippets.visualWithQuoteFactObject},
                  _type == 'events' => ${snippets.eventsLatestThreeObject},
                  _type == 'campaignBanner' => ${snippets.campaignBannerObject},
                  markDefs[] {
                    ...,
                    _type == 'internalLink' => { ref->${snippets.linkableDocumentObject} }
                  }
                },
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
                seo,
              }`,
              { language, slug }
            ]
          }),
          extractParams: { genericPage: extract(language, slug) },
          derived: ({ data, derived }) => data.doc ? {
            doc: docWithSeo(data.doc, {
              title: data.doc.title,
              social: {
                shareImage: data.doc.hero?.image ?? data.settings?.shareImage
              }
            }),
            dataLayer: {
              ...derived.dataLayer,
              title: data.doc.title,
              metadata: {
                ...derived.dataLayer?.metadata,
                content: {
                  type: data.doc._type,
                  title: data.doc.title,
                  id: data.doc._id,
                  createdat: dayjs(data.doc._createdAt).format('YYYY-MM-DD'),
                  updatedat: dayjs(data.doc._updatedAt).format('YYYY-MM-DD'),
                  language: data.doc.language,
                }
              }
            }
          } : notFound(data),
        }
      },

      events: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: [
              groq`*[_type == 'eventsOverview' && language == $language] | order(_updatedAt desc) [0] {
                ...,
                title,
                hero ${snippets.heroObject},
                'events': ${snippets.eventsAllQuery},
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
                language,
                seo,
              }`,
              { language }
            ]
          }),
          extractParams: { eventsOverview: extract(language) },
          derived: ({ data, derived }) => data.doc ? {
            doc: docWithSeo(data.doc, {
              title: data.doc.title,
              social: {
                shareImage: data.doc.hero?.image ?? data.settings?.shareImage
              }
            }),
            dataLayer: {
              ...derived.dataLayer,
              title: data.doc.title,
              metadata: {
                ...derived.dataLayer?.metadata,
                content: {
                  type: data.doc._type,
                  title: data.doc.title,
                  id: data.doc._id,
                  createdat: dayjs(data.doc._createdAt).format('YYYY-MM-DD'),
                  updatedat: dayjs(data.doc._updatedAt).format('YYYY-MM-DD'),
                  language: data.doc.language,
                }
              }
            }
          } : notFound(data),
        },
      },

      notFound: {
        data: {
          derived: ({ data }) => notFound(data),
        },
      },
    }
  }
}

function notFound(data) {
  return {
    dataLayer: {
      metadata: {
        content: {
          type: '404',
          title: 'Page not found',
          language: data.language,
        }
      }
    }
  }
}

function createGroqSnippets() {
  const linkableDocumentFields = groq`_type, slug, language`
  const linkableDocumentObject = groq`{${linkableDocumentFields}}`
  const linkableDocumentObjectWithTitle = groq`{ title, ${linkableDocumentFields} }`

  const referenceWithLabelObject = groq`{_type, label, ref->${linkableDocumentObject}}`

  const internalLinkFields = groq`
    title,
    ref->${linkableDocumentObject},
    link->${linkableDocumentObject}
  `
  const internalLinkObject = groq`{ ${internalLinkFields} }`
  const externalLinkObject = groq`{ title, href }`

  const eventObject = groq`{
    _id,
    title,
    description,
    location,
    startDate,
    endDate,
    time,
    externalLink,
    image
  }`

  const traineeshipObject = groq`{
    title,
    location,
    discipline,
    "url": @.externalLink.href
  }`

  const todayAsISOString = dayjs().startOf('day').toISOString()

  const eventsLatestThreeQuery = groq`
    *[_type == 'event' && language == @.language && dateTime(endDate + 'T00:00:00Z') >= dateTime('${todayAsISOString}')] | order(startDate asc, publicationDate desc) [0...3]
      ${eventObject}
  `

  const eventsAllQuery = groq`
    *[_type == 'event' && language == @.language && dateTime(endDate + 'T00:00:00Z') >= dateTime('${todayAsISOString}')] | order(startDate asc, publicationDate desc)
      ${eventObject}
  `

  const traineeshipsQuery = groq`
    *[_type == 'traineeship' && language == @.language] | order(startDate asc, publicationDate desc)
      ${traineeshipObject}
  `

  const heroObject = groq`{
    title,
    text,
    image,
  }`

  const videoObject = groq`{
    url,
    title,
    buttonText,
    urls {
      quality1080,
      quality720,
      quality540,
      quality360
    }
  }`

  const visualWithQuoteFactObject = groq`{
    title,
    text,
    image,
  }`

  const heroVideoObject = groq`{
    title,
    text,
    image,
    video ${videoObject}
  }`

  const disciplineAreaListObject = groq`{
    title,
    disciplines[]-> {
      title,
      shortDescription,
      hero ${heroObject},
      _type,
      _id,
      slug,
      language
    }
  }`

  const campaignBannerObject = groq`{
    title,
    text,
    images,
    linkPrimary ${referenceWithLabelObject},
    linkSecondary ${referenceWithLabelObject},
    video,
  }`

  const ctaNextInitiativeObject = groq`{
    initiative-> {
      title,
      hero ${heroObject},
      language,
      _type,
      _id,
      slug,
    }
  }`

  const videoTextObject = groq`{
    blocks[] { ..., markDefs[] },
    video ${videoObject}
  }`

  const initiativeListObject = groq`{
    title,
    buttonText,
    initiatives[]-> {
      title,
      hero ${heroObject},
      _type,
      _id,
      slug,
      language,
    }
  }`

  const anchorToJobCardsObject = groq`{
    title,
    subtitle,
    buttonText,
  }`

  const factsObject = groq`{
    title,
    facts[] {
      title,
      text
    },
  }`

  const eventsLatestThreeObject = groq`{
    title,
    description,
    'events': ${eventsLatestThreeQuery}
  }`

  const employeeQuoteObject = groq`{
    text,
    attribution,
  }`

  const dataVisualizationObject = groq`{
    showBackground,
    image,
  }`

  return {
    linkableDocumentObjectWithTitle,
    linkableDocumentObject,
    internalLinkObject,
    externalLinkObject,
    heroObject,
    heroVideoObject,
    videoObject,
    videoTextObject,
    ctaNextInitiativeObject,
    visualWithQuoteFactObject,
    disciplineAreaListObject,
    initiativeListObject,
    anchorToJobCardsObject,
    factsObject,
    employeeQuoteObject,
    dataVisualizationObject,
    traineeshipsQuery,
    eventsLatestThreeObject,
    campaignBannerObject,
    eventsAllQuery,
    translations(projection) {
      return groq`
        *[
          _id != ^._id &&
          _type == ^._type &&
          translationId == ^.translationId
        ]
        ${projection}
      `
    },
  }
}
